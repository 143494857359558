(function () {
    catalog.directive('baiMainContent', ['$location', 'UsersService',
        function ($location, UsersService) {
            return {
                restrict: 'E',
                templateUrl: function() { 
                    return 'App/templates/components/' + (
                        $location.$$path.indexOf('show-tabbed-header') > 0 &&
                        UsersService.getCurrentRoleType().toLowerCase() === 'ta' &&
                        ['/users/', '/courses/', '/rules/', '/management/', '/insights/', '/dashboard/'].filter(function (item) { return $location.$$path.toLowerCase().startsWith(item); }).length > 0 ?
                        'adminMainContent.html' : 'mainContent.html'
                    ); },
                link: baiMainContentLink,
                controller: ['$location', '$scope', function BaiMainContentCtrl($location, $scope) {
                    $scope.goToRoute = function(url) {
                        $location.url(url);
                    };
                }]
            };
        }
    ]);

    function baiMainContentLink(scope, element, attrs) {
        if (attrs !== undefined) {
            scope.hideFooter = attrs.hideFooter === "true";
        }
    }

})();
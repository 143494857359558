app.factory('Management_AdminOverride', ['$httpParamSerializer', '$injector', '$location', '$q', '$route', '$timeout', '$filter', 'CountriesService', 'DocumentService', 'JobsService', 'LocationsService', 'OrganizationsService', 'TimezoneService', 'UsersService', 'RoleMapsMixin', 'OrgFunctions', 'dateUtil', 'errorDisplayerUtil', 'successDisplayerUtil', 'SettingsService', 'StatesService', 'TreeHelpers', 'CategoriesService', 'CatalogService',
    function ($httpParamSerializer, $injector, $location, $q, $route, $timeout, $filter, CountriesService, DocumentService, JobsService, LocationsService, OrganizationsService, TimezoneService, UsersService, RoleMapsMixin, OrgFunctions, dateUtil, errorDisplayerUtil, successDisplayerUtil, SettingsService, StatesService, TreeHelpers, CategoriesService, CatalogService) {
        return function($scope) {
            $scope.SettingsService = SettingsService;
            var _this = this;

            /* This will hide the breadcrumbs and search form on the top right of header */
            $scope.defaultHeader = true;
            $scope.hideCatalogSearch = true;

            /* This will make the navigation tab active on certain page */
            $scope.activeTab = 'tab-active-class';

            /*This will route users to the appropiate user manual based on active tab*/
            $scope.userManualHref = 'https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Management';

            $scope.customDocumentsEnabled = window.customDocumentsEnabled;

            /* Training Insights access determined by the organization settings */
            var sett = SettingsService.getSettings().then(function (success) {
                $scope.showTrainingInsights = SettingsService.isTrainingInsightsEnabled();
                $scope.showCareerPath = SettingsService.isCareerPathEnabled();
                $scope.showCustomContent = SettingsService.isILTEnabled();
                //need to add this here because of race conditions
                if ($route.current.params.name === 'createcategory' || $route.current.params.name === 'editcategory') {
                    if (!$scope.showCustomContent && $scope.allUniqueCategories !== undefined) { $scope.showOnlyDocumentTaxonomy(); }
                }
            });

            /**
             * Keeps the search value for all the different types of right side searches
             *
             * @type {string}
             */
            $scope.management = {};
            $scope.management.managementSearch = '';
            $scope.propertyName = 'name';
            $scope.reverse = false;

            $scope.home = {};
            $scope.home.limit = 50;
            $scope.home.maxVisiblePages = 10;
            _this.scopedViewListData = [];

            $scope.modalIncludes = {};

            /* This gets called by the UsersController
             * Consider it the entry point of the TA page.
             */
            $scope.homeAction = function () {
                $scope.home.page = 1;
                $scope.home.total = 0;
                $scope.homeTemplate = 'app/templates/management/home/admin.html';
            };

			
           $scope.containerPopover = {};	
           $scope.containerPopover.isOpen = false;
            // Organization's related logic follows below

            $scope.OrgTreeTitle = "Organizations";
            $scope.orgTableError = {};
            $scope.orgTableError.error = errorDisplayerUtil.create();
            $scope.OrgOptions = {};
            $scope.OrgData = [{ 'name': 'Organizations', 'nodes': [] }];
            $scope.OrgData[0].nodes.push({ 'nodes': [], 'name': 'Active', 'lockIcon': true });
            $scope.OrgData[0].nodes.push({ 'nodes': [], 'name': 'Inactive', 'lockIcon': true });
            $scope.orgCutoff = 100;
            _this.originalOrgList = [];

            $scope.deleteModal = {};
            $scope.deleteModal.CoursesData = [];

            $scope.vieworganizationsAction = function () {
                $scope.home.page = 1;
                $scope.homeTemplate = 'app/templates/management/vieworganizations/admin.html';

                $scope.management.managementSearch = '';

                $scope.orgTableError.error.reset();
                $scope.orgTableIsLoading = true;
                _this.isOrgsListPage = true;
                $scope.OrgOptions.initiallyOpened = true;
                $scope.OrgOptions.showLocks = false;
                $scope.orgSelected = $scope.OrgData[0];

                //Determine if there is any filter selected, if so, expand Organizations and select the node
                if (typeof ($route.current.params.orgFilter) !== 'undefined') {
                    var filterSel = $route.current.params.orgFilter.toLowerCase() === 'active' ? 0 : 1;
                    $scope.orgSelected = $scope.OrgData[0].nodes[filterSel];
                    $scope.orgExpanded = [$scope.OrgData[0]];
                }

                var organizationsDataPromise = OrganizationsService.getOrganizationHierarchy();
                organizationsDataPromise.then(function (success) {
                    _this.originalOrgList = success;

                    if ($route.current.params.orgFilter) {
                        $scope.OrgOptions.initiallyOpened = true;
                        var filter = $route.current.params.orgFilter;
                        $scope.OrgNodeClick({ 'name': filter });
                    } else {
                        _this.scopedViewListData = (success);
                        $scope.rows = success.slice(0, $scope.home.limit);
                        _this.calculatePages(success);
                        $scope.orgTableIsLoading = false;
                    }
                }, function(failure) {
                    $scope.orgTableIsLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.orgTableError.error.setError(errorCode, message);
                });
            };

            $scope.home.nextPage = function () {
                var searchTerm = $scope.management.managementSearch;
                if (searchTerm) {
                    _this.SearchData();
                } else {
                    _this.setStartandEndIndex();
                    $scope.rows = _this.scopedViewListData.slice($scope.startIndex, $scope.endIndex);
                }
            };

            $scope.general = {};
            $scope.general.peopleSearch = null;

            $scope.general.filterBySearch = function () {
                $scope.home.page = 1;
                _this.SearchData();
            };

            //this function is used for the 'simple search'
            _this.SearchData = function() {
                var searchTerm = $scope.management.managementSearch;
                

                var filtered = [];
                if (_this.isOrgsListPage) {
                    filtered = $filter('filter')(_this.scopedViewListData, function (row) {
                            return ((row.name.toLowerCase()).indexOf((searchTerm.toLowerCase()) || '') !== -1 ||
                                (!row.isTopLevelOrg && (row.parentOrgName.toLowerCase()).indexOf((searchTerm.toLowerCase()) || '') !== -1)
                            );
                        }
                    );
                } else if (_this.isJobsListPage) {
                    filtered = $filter('filter')(_this.scopedViewListData, function (row) {
                            return ((row.name.toLowerCase()).indexOf((searchTerm.toLowerCase()) || '') !== -1 ||
                                ((row.code.toLowerCase()).indexOf((searchTerm.toLowerCase()) || '') !== -1)
                            );
                        }
                    );
                }

                _this.calculatePages(filtered);
                _this.setStartandEndIndex();
                $scope.rows = filtered.slice($scope.startIndex, $scope.endIndex);
            };

            // Organization object that gets sent to server
            $scope.organization = {};
            // create organization namespace
            $scope.updateOrg = {};
            // holds angular error/form logic for creating organizations
            $scope.updateOrg.orgform = {};
            $scope.updateOrg.error = errorDisplayerUtil.create();
            $scope.updateOrg.isLoading = false;

            $scope.updateOrg.organizationCta = function() {
                /* portalId of the organization should always
                 * be the user's portalId */
                $scope.updateOrg.isLoading = true;
                var orgData = $scope.organization;
                var params = $route.current.params;
                orgData.updateDateTime = moment().format();

                var method;
                if (params.name.includes('create')) {
                    method = 'POST';
                } else if (params.name.includes('edit')) {
                    method = 'PUT';
                }

                var organization = OrganizationsService.updateOrganization(orgData, method);
                organization.then(function(success) {
                    $scope.updateOrg.isLoading = false;
                    $location.url('management/vieworganizations/');
                }, function(failure) {
                    $scope.updateOrg.isLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.updateOrg.error.setError(errorCode, message);
                });
            };

            $scope.viewOrgForm = {};
            $scope.viewOrgForm.error = errorDisplayerUtil.create();
            $scope.viewOrgForm.isLoading = false;

            /******* CREATE ORGANIZATION ******/
            $scope.createorgAction = function() {
                $scope.homeTemplate = 'app/templates/management/createorg/admin.html';
                $scope.createHeader = true;
                $scope.viewOrgForm.isLoading = true;

                var orgCreateObj = OrganizationsService.createOrgObj(true);
                var organizationsDataPromise = OrganizationsService.getOrganizationHierarchy();
                var all = $q.all([orgCreateObj, organizationsDataPromise]);

                all.then(function (success) {
                    $scope.viewOrgForm.isLoading = false;
                    $scope.organization = success[0];
                    $scope.orgList = (_this.formatOrgDataForTypeahead(success[1]));
                   
                    // Organizations should be enabled by default.
                    $scope.organization.enabled = true;
                }, function (error) {
                    $scope.viewOrgForm.isLoading = false;
                    var errorCode = error.StatusCode;
                    var message = error.ErrorMessage;
                    $scope.viewOrgForm.error.setError(errorCode, message);
                });

            };

            /* ************* EDIT ORGANIZATION *******/
            $scope.editorganizationAction = function() {
                $scope.homeTemplate = 'app/templates/management/editorganization/admin.html';
                $scope.createHeader = false;

                $scope.viewOrgForm.isLoading = true;
                var params = $route.current.params.param.split('/');
                var orgId = parseInt(params[0]);

                var singleOrgPromise = OrganizationsService.getSingleOrg(orgId);
                var organizationsDataPromise = OrganizationsService.getOrganizationHierarchy();
                var all = $q.all([singleOrgPromise, organizationsDataPromise]);
                all.then(function (success) {
                    $scope.viewOrgForm.isLoading = false;
                    $scope.organization = success[0];
                    $scope.orgList =  _this.formatOrgDataForTypeahead(success[1]);
                }, function(error) {
                    $scope.viewOrgForm.isLoading = false;
                    var errorCode = error.StatusCode;
                    var message = error.ErrorMessage;
                    $scope.viewOrgForm.error.setError(errorCode, message);
                });
            };

            $scope.selectParentOrganization = function ($item, $model, $label, $event, organization) {
                $scope.organization.parentId = $item.id;
            };

            _this.formatOrgDataForTypeahead = function(orgData) {
                var temporgList = [];
                $scope.orgCutoff = 100; // cutoff point for dropdown vs uib-typeahead


                var activeOrgs = _.filter(orgData, function (org) {
                    return (org.enabled === true);
                });

                for (var i = 0; i < activeOrgs.length; i++) {
                    var aorg = (activeOrgs[i]);
                    temporgList.push({ "name": aorg.name, "id": aorg.id });
                }
                return temporgList;
            };

            $scope.OrgNodeClick = function (orgFilter) {
                $scope.home.page = 1;
                $scope.management.managementSearch = '';
                /* If we're not on the Org list page,
                 * redirect to the Orgs list page and filter Orgs by active/inactive or none */
                if (!_this.isOrgsListPage) {
                    var searchParams = {};

                    if (orgFilter.name === 'Active') {
                        searchParams.orgFilter = 'Active';
                    }

                    if (orgFilter.name === 'Inactive') {
                        searchParams.orgFilter = 'Inactive';
                    }

                    var newLocation = 'management/vieworganizations/';
                    searchParams = $httpParamSerializer(searchParams);

                    $location.path(newLocation).search(searchParams);
                    return;
                }
                
                if (orgFilter.name === 'Active') {
                    _this.scopedViewListData = _.filter(_this.originalOrgList, { "enabled": true });
                }
                else if (orgFilter.name === 'Inactive') {
                    _this.scopedViewListData = _.filter(_this.originalOrgList, { "enabled": false });
                } 
                
                $scope.rows = _this.scopedViewListData.slice(0, $scope.home.limit);
                _this.calculatePages(_this.scopedViewListData);
                $scope.orgTableIsLoading = false;
                return;
            };


            /* *********** JOBS TREE, JOBS LIST AND EDITING JOBS *******/
            $scope.jobsList = {};
            $scope.jobsList.error = errorDisplayerUtil.create();
            $scope.jobsList.isLoading = false;
            $scope.jobsList.options = {};
            $scope.jobsList.options.showLocks = false;
            $scope.jobsList.title = "Positions";
            $scope.jobsList.inputData = [{ 'name': 'Positions', 'nodes': [] }];
            $scope.jobsList.inputData[0].nodes.push({ 'nodes': [], 'name': 'Active' });
            $scope.jobsList.inputData[0].nodes.push({ 'nodes': [], 'name': 'Inactive' });
            $scope.jobsList.jobs = [];
            _this.originalJobs = [];
            /* For any jobs related page, if this
             * variable is false, it means we're not on 
             * lms-web/management/viewjobs/
             * 
             * Used by the node click on the jobs tree component.
             * That way when someone tries to filter active jobs on the job edit page for example,
             * the user can be redirected to lms-web/manage/view-jobs/?jobs=active
             */
            _this.isJobsListPage = false;

            $scope.viewjobsAction = function () {
                $scope.home.page = 1;
                $scope.jobsList.error.reset();

                $scope.homeTemplate = 'app/templates/management/viewjobs/admin.html';

                $scope.jobSelected = $scope.jobsList.inputData[0];
                //Determine if there is any filter selected, if so, expand Positions and select the node
                if (typeof ($route.current.params.jobFilter) !== 'undefined') {
                    var filterSel = $route.current.params.jobFilter.toLowerCase() === 'active' ? 0 : 1;
                    $scope.jobSelected = $scope.jobsList.inputData[0].nodes[filterSel];
                    $scope.jobExpanded = [$scope.jobsList.inputData[0]];
                }

                _this.isJobsListPage = true;

                $scope.jobsList.isLoading = true;
                $scope.jobsList.error.reset();

                var jobs = JobsService.getAllJobs();

                $scope.jobsList.options.initiallyOpened = true;

                jobs.then(function(success) {
                    $scope.jobsList.isLoading = false;
                    _this.originalJobs = (success);

                    if ($route.current.params.jobFilter) {
                        $scope.jobsList.options.initiallyOpened = true;
                        var filter = $route.current.params.jobFilter;
                        $scope.selectJobFilter({ 'name': filter });
                    } else {
                        _this.scopedViewListData = (success);
                        $scope.rows = success.slice(0, $scope.home.limit);
                    }

                }, function(failure) {
                    $scope.jobsList.isLoading = false;
                    $scope.jobsList.error.setError(failure.ErrorCode, failure.ErrorMessage);
                });
            };

            /**
             * Namespace for editing call.
             *
             * @type {object}
             */
            $scope.editJob = {};
            $scope.editJob.baiForm = {};
            $scope.editJob.error = errorDisplayerUtil.create();
            $scope.editJob.isLoading = false;

            $scope.editJob.closePopover = function() {
                $scope.editJob.popoverOpen = false;
            };

            $scope.editjobAction = function() {
                $scope.homeTemplate = 'app/templates/management/editjob/admin.html';

                var params = $route.current.params.param.split('/');

                var jobId = parseInt(params[0]);

                $scope.editJob.error.reset();

                var job = JobsService.getAllJobs();
                $scope.editJob.isLoading = true;

                job.then(function(success) {
                    var job = _.filter(success, function(job) {
                        return (job.id === jobId);
                    });
                    if (job.length > 0) {
                        job[0].lastUpdate = moment(job[0].lastUpdate);
                        $scope.job = job[0];
                    } else {
                        $scope.editJob.error.setError("404", "Position not found");
                    }

                    $scope.editJob.jobNames = _.map(success, function(job) {
                        /* Don't add the name of the job we're editing
                         * because we want the user to be able to save a job
                         * with that name
                         */
                        if ($scope.job && job.id === $scope.job.id) {
                            return null;
                        }
                        return job.name;
                    });

                    $scope.editJob.isLoading = false;
                }, function(failure) {
                    $scope.editJob.error.setError(failure.ErrorCode, failure.ErrorMessage);
                    $scope.editJob.isLoading = false;
                });
            };

            // Called on edit job page save button click
            $scope.editJob.updateJob = function(optionalToggleActiveStatus) {
                $scope.editJob.isLoading = true;
                /* Close the popover in case this
                 * function was called from the popover */
                $scope.editJob.closePopover();

                /* I could pass $scope.job as-is but 
                 * if I change the isActive field,
                 * it'll propagate to the GUI and that hurts the user
                 * experience */
                var job = angular.copy($scope.job);

                if (job.nodes)
                    delete job.nodes;
                if (job.lastUpdate)
                    delete job.lastUpdate;

                if (optionalToggleActiveStatus) {
                    job.isActive = !job.isActive;
                }

                JobsService.updateJob(job).then(function(success) {
                    window.location = 'management/viewjobs/';
                }, function(failure) {
                    $scope.editJob.isLoading = false;

                    $scope.editJob.error.setError(failure.ErrorCode, failure.ErrorMessage);
                });
            };

            /* If this is on the jobs list page do one thing
             * If it's not on the jobs list page do something else */

            // Only filters jobs row
            $scope.selectJobFilter = function (jobFilter) {
                $scope.home.page = 1;
                $scope.management.managementSearch = '';
                /* If we're not on the jobs list page,
                 * redirect to the jobs list page and filter jobs by active/inactive or none */
                if (!_this.isJobsListPage) {
                    var searchParams = {};

                    if (jobFilter.name === 'Active') {
                        searchParams.jobFilter = 'Active';
                    }

                    if (jobFilter.name === 'Inactive') {
                        searchParams.jobFilter = 'Inactive';
                    }

                    var newLocation = 'management/viewjobs/';
                    searchParams = $httpParamSerializer(searchParams);

                    $location.path(newLocation).search(searchParams);
                    return;
                }

                
                if (jobFilter.name === 'Inactive') {
                    // Show all inactive jobs
                    _this.scopedViewListData = _.filter(_this.originalJobs, { "isActive": false });
                } else {
                    // Default - Show all active jobs (jobFilter.name === 'Active')
                    _this.scopedViewListData = _.filter(_this.originalJobs, { "isActive": true });
                }
                
                $scope.rows = _this.scopedViewListData.slice(0, $scope.home.limit);
                _this.calculatePages(_this.scopedViewListData);
                return;
            };

            // Jobs Creation Starts From Here
            $scope.createJob = {};
            $scope.createJob.baiform = {};
            $scope.createJob.inputJobName = false;
            $scope.createJob.error = errorDisplayerUtil.create();

            $scope.createjobAction = function() {
                //default template for both save and edit people group form peopleTab
                $scope.homeTemplate = 'app/templates/management/createJob/admin.html';
                // input for creating new people group name
                $scope.createJob.inputJobName = true;
                // people group template header changes based on create/update
                $scope.createJob.defaultJobHeader = true;
                //Get the list of jobs to check that we do not duplicate the name
                _this.isJobsListPage = true;

                var job = JobsService.getAllJobs();

                job.then(function(success) {
                    $scope.editJob = {};
                    $scope.editJob.jobNames = _.map(success, function(job) {
                        return job.name;
                    });

                }, function(failure) {
                    //Do nothing
                });

                $scope.job = {};
            };

            $scope.containerOptions = {};
            $scope.containerOptions.showLock = false;
            $scope.createJob.success = successDisplayerUtil.create();

            $scope.createJob.createJob = function(job) {

                //Generate a guid for the job code
                job.code = sqlguid();

                JobsService.createJob(job).then(function(success) {
                    //Show Success message
                    $scope.createJob.success.set('The Position has been saved.');
                    //Redirect to job list              
                    setTimeout(function() {
                        $scope.viewjobsAction();
                        return $location.url('management/viewjobs/');
                    }, 2000);
                }, function(failure) {
                    $scope.createJob.isLoading = false;

                    $scope.createJob.error.setError(failure.ErrorCode, failure.ErrorMessage);

                });
            };

            /**
             * Namespace to avoid collisions with other loaders.
             *
             * @type {object}
             */
            $scope.manageLocation = {};
            $scope.manageLocation.error = errorDisplayerUtil.create();
            $scope.manageLocation.countriesLoading = false;

            $scope.manageLocation.popoverOpen = false;

            $scope.manageLocation.closePopover = function() {
                $scope.manageLocation.popoverOpen = false;
            };

            /**
             * Namespace for creation call.
             *
             * @type {object}
             */
            $scope.createLocation = {};
            $scope.createLocation.error = errorDisplayerUtil.create();
            $scope.manageLocation.baiform = {};

            /**
             * Namespace for editing call.
             *
             * @type {object}
             */
            $scope.editLocation = {};
            $scope.editLocation.error = errorDisplayerUtil.create();

            /**
             * Create Location action endpoint.
             *
             * @void
             */
            $scope.createlocationAction = function () {
                $scope.location = {};

                $scope.homeTemplate = 'app/templates/management/createlocation/admin.html';

                var timezones = TimezoneService.getAllTimezones();
                timezones.then(function(data) {
                    $scope.timezones = data;
                }, function(failure) {
                    // Do nothing
                });

                $scope.manageLocation.error.reset();

                $scope.types = LocationsService.types;

                var countries = CountriesService.getLatitudeCountryList(UsersService.getPortalId());
                countries.then(function(success) {
                    $scope.countries = success;
                    $scope.selectedCountry = _.findIndex($scope.countries, { countryName: 'United States' }) === -1 ? null : $scope.countries[_.findIndex($scope.countries, { countryName: 'United States' })].countryId; //to set it to united states when filling countrylist for first time when creating new location
                    $scope.getStates($scope.selectedCountry);
                    $scope.manageLocation.countriesLoading = false;
                }, function(failure) {
                    // @TODO: @sfrohm fail page totally
                });

            };

            $scope.initNewLocationStartDate = function() {
                if (typeof $scope.location !== "undefined" && (typeof $scope.location.startDate === 'undefined' || $scope.location.startDate === null)) {
                    var startDateTime = dateUtil.setPortalStartTime(new Date());
                    $scope.location.startDate = dateUtil.formatDate(startDateTime);
                }
            };

            /**
             * Creates a location after a user completes the form and submits
            */
            $scope.sendCreateLocationCall = function () {
                var locationData = angular.copy($scope.location);
                if (typeof $scope.location.startDate !== "undefined" && typeof $scope.location.startDate.moment !== "undefined") {
                    locationData.startDate = locationData.startDate.moment.toISOString();
                } else {
                    locationData.startDate = null;
                }

                var create = LocationsService.create(locationData);

                create.then(function(success) {
                    return $location.url('management/viewlocations/');
                }, function(failure) {
                    $scope.createLocation.error.setError(failure.ErrorCode, failure.ErrorMessage);
                });
            };

            $scope.editlocationAction = function() {
                $scope.homeTemplate = 'app/templates/management/editlocation/admin.html';

                var params = $route.current.params.param.split('/');

                var locationId = params[0];

                $scope.manageLocation.error.reset();

                $scope.types = LocationsService.types;

                var timezones = TimezoneService.getAllTimezones();
                timezones.then(function(data) {
                    $scope.timezones = data;
                }, function(failure) {
                    // Do nothing
                });

                var countries = CountriesService.getLatitudeCountryList(UsersService.getPortalId());
                countries.then(function(success) {
                    $scope.countries = success;

                    $scope.manageLocation.countriesLoading = false;
                }, function(failure) {
                    // @TODO: @sfrohm what do we do when this fails? API is not going to complete a POST with missing data
                });

                var location = LocationsService.getSingle(locationId);
                location.then(function(success) {
                    $scope.location = success;

                    var locationAddress = $scope.location.physicalAddress;
                    var defaultCountry = _.findIndex($scope.countries, { countryName: 'United States' }) === -1 ? null : $scope.countries[_.findIndex($scope.countries, { countryName: 'United States' })].countryId;
                    $scope.selectedCountry = $scope.location.physicalAddress.countryId === null ? $scope.location.physicalAddress.countryId = defaultCountry : $scope.location.physicalAddress.countryId ; // if countryid is null (no country selected) then set it to UnitedStates else country itself
                    // Get a state list for the user, based on country
                    if (typeof locationAddress !== 'undefined' && locationAddress !== null && locationAddress.countryId !== null && parseInt(locationAddress.countryId, 10) > 0) {
                        $scope.getStates($scope.location.physicalAddress.countryId);
                    }

                }, function(failure) {
                    $scope.manageLocation.error.setError(failure.ErrorCode, failure.ErrorMessage);
                });
            };

            $scope.getStates = function (countryId) {

                var states = StatesService.getLatitudeStateList(countryId);

                states.then(function(stateList) {
                    $scope.states = stateList;
                }, function(error) {
                    $scope.states = [];
                });
            };


            /**
             * Edits a location after a user completes the form and submits
             *
             * @void
             */
            $scope.sendEditLocationCall = function(deactivate) {
                $scope.editLocation.error.reset();

                var locationData = angular.copy($scope.location);
                if (typeof $scope.location.startDate !== "undefined" && typeof $scope.location.startDate.moment !== "undefined") {
                    var startDateTime = dateUtil.setPortalStartTime(locationData.startDate.moment.toDate());
                    locationData.startDate = startDateTime.toISOString();
                } else {
                    locationData.startDate = null;
                }

                if (typeof deactivate !== 'undefined') {
                    if (deactivate === true) {
                        locationData.endDate = new Date();
                    } else {
                        locationData.startDate = new Date();
                        locationData.endDate = null;
                    }
                } 

                var edit = LocationsService.edit(locationData);
                edit.then(function(success) {
                    return $location.url('management/viewlocations/');
                }, function(failure) {
                    $scope.editLocation.error.setError(failure.ErrorCode, failure.ErrorMessage);
                });
            };

            $scope.locationsList = {};
            $scope.locationsList.showLocks = false;
            $scope.locationsList.title = "Locations";
            /**
             * Hardcoded filter list, specifically for filtering by active/inactive only. Can be reused anywhere,
             * in unison with locationsList.options.searchUpdateCallback.
             */
            $scope.locationsList.treeData = [{ 'name': 'Locations', 'nodes': [] }];
            $scope.locationsList.treeData[0].nodes.push({ 'nodes': [], 'name': 'Active', 'value': true });
            $scope.locationsList.treeData[0].nodes.push({ 'nodes': [], 'name': 'Inactive', 'value': false });

            $scope.locationsList.error = errorDisplayerUtil.create();
            $scope.locationsList.isLoading = false;
            $scope.locationsList.options = {};
            $scope.locationsList.options.showLocks = false;

            _this.isLocationsListPage = false;

            $scope.viewlocationsAction = function() {
                $scope.locationsList.error.reset();
                _this.isLocationsListPage = true;
                $scope.locationsList.options.initiallyOpened = true;

                $scope.homeTemplate = 'app/templates/management/viewlocations/admin.html';

                $scope.locationSelected = $scope.locationsList.treeData[0];
                //Determine if there is any filter selected, if so, expand Locations and select the node
                if (typeof ($route.current.params.locationFilter) !== 'undefined') {
                    var filterSel = $route.current.params.locationFilter.toLowerCase() === 'active' ? 0 : 1;
                    $scope.locationSelected = $scope.locationsList.treeData[0].nodes[filterSel];
                    $scope.locationExpanded = [$scope.locationsList.treeData[0]];
                }

                $scope.locationsList.isLoading = true;
                $scope.locationsList.error.reset();

                var facilities = LocationsService.getAll();
                facilities.then(function(success) {
                    $scope.locationsList.isLoading = false;

                    $scope.locationsList.data = success;
                    _this.locationsListData = success;

                    if ($route.current.params.locationFilter) {
                        $scope.locationsList.options.initiallyOpened = true;

                        var filter = $route.current.params.locationFilter;

                        $scope.locationsList.filterLocations({ 'name': filter });
                    }
                }, function(failure) {
                    $scope.locationsList.isLoading = false;
                    $scope.locationsList.error.setError(failure.ErrorCode, failure.ErrorMessage);
                });
            };

            /**
             * Callback to ensure proper filtering is set, as this filtering is not done by ID.
             */

            $scope.locationsList.filterLocations = function(locationFilter) {
                /* If we're not on the Org list page,
                 * redirect to the Orgs list page and filter Orgs by active/inactive or none */
                if (!_this.isLocationsListPage) {
                    var searchParams = {};

                    if (locationFilter.name === 'Active') {
                        searchParams.locationFilter = 'Active';
                    }

                    if (locationFilter.name === 'Inactive') {
                        searchParams.locationFilter = 'Inactive';
                    }

                    var newLocation = 'management/viewlocations/';
                    searchParams = $httpParamSerializer(searchParams);

                    $location.path(newLocation).search(searchParams);
                }

                if (locationFilter.name === 'Locations') {
                    // Show all locations
                    $scope.locationsList.data = _this.locationsListData;
                }

                if (locationFilter.name === 'Inactive') {
                    // Show all inactive locations
                    $scope.locationsList.data = _.filter(_this.locationsListData, function(location) {
                        return (location.isActive === false);
                    });
                }

                if (locationFilter.name === 'Active') {
                    // Show all inactive locations
                    $scope.locationsList.data = _.filter(_this.locationsListData, function(location) {
                        return (location.isActive === true);
                    });
                }
            };


            $scope.DocTreeTitle = "Documents";
            $scope.docTableError = {};
            $scope.docTableError.error = errorDisplayerUtil.create();
            $scope.DocOptions = {};
            $scope.DocOptions.showLocks = false;
            $scope.DocOptions.canDrillDown = false;
            $scope.DocData = [{ 'name': 'Documents', 'nodes': [] }];
            $scope.docTableIsLoading = false;

            $scope.viewdocumentsAction = function() {
                $scope.homeTemplate = 'app/templates/management/viewdocuments/admin.html';

                $scope.docTableError.error.reset();
                $scope.docTableIsLoading = true;
                _this.isDocsListPage = true;
                $scope.DocOptions.initiallyOpened = true;
                $scope.docSelected = $scope.DocData[0];

                var organizationsDataPromise = DocumentService.getDocumentRepositoryList();
                organizationsDataPromise.then(function(success) {
                    $scope.docTableIsLoading = false;
                    $scope.rows = success;
                    $scope.docsListData1 = success;
                    _this.docsListData = success;

                }, function(failure) {
                    $scope.docTableIsLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.docTableError.error.setError(errorCode, message);
                });
            };

            $scope.DocNodeClick = function(docFilter) {
                if (docFilter.name === "Documents") {
                    $location.path('management/viewdocuments');
                }
            };

            $scope.updateDocument = {};
            $scope.updateDocument.documents = [];
            $scope.updateDocument.error = errorDisplayerUtil.create();
            $scope.updateDocument.success = successDisplayerUtil.create();
            $scope.updateDocument.isLoading = false;

            $scope.createdocumentAction = function() {
                $scope.homeTemplate = 'app/templates/management/createdocument/admin.html';

                $scope.createHeader = true;
            };

            $scope.sendDocumentCreateCall = function() {
                var documentList = $scope.updateDocument.documents;
                var filesToUpload = [];

                $scope.updateDocument.error.reset();
                $scope.updateDocument.success.reset();

                $scope.updateDocument.isLoading = true;

                if (typeof documentList !== 'undefined' && documentList.length > 0) {
                    for (var j = 0; j < documentList.length; j += 1) {
                        // Uploading a document from browser
                        if (documentList[j].fileOrUrl === 'file' && !documentList[j].fromRepo) {
                            filesToUpload.push(DocumentService.uploadDocumentToRepository(documentList[j].fileObject));
                        } else if (documentList[j].fileOrUrl === 'url') {
                            filesToUpload.push(DocumentService.uploadUrlToRepository(documentList[j].name));
                        }
                    }

                    $q.all(filesToUpload).then(function(success) {
                        $scope.updateDocument.isLoading = false;

                        $scope.updateDocument.documents = [];

                        $scope.updateDocument.success.set('The document was successfully uploaded!');
                    }, function(failure) {
                        $scope.updateDocument.isLoading = false;

                        $scope.updateDocument.error.setError(failure.ErrorCode, failure.ErrorMessage);
                    });
                }
            };

            $scope.clickDeleteDocument = function () {
                if ($scope.deleteModal.CoursesData.length > 0) {
                    var html = '';
                    jQuery($scope.deleteModal.CoursesData).each(function (i, course) {
                        html += '<tr><td class="col-md-2">' + course.courseCode + '</td><td class="col-md-3">' + course.courseType + '</td><td class="col-md-3">' + course.courseName + '</td></tr>';
                    });
                    jQuery('#viewDeleteDocumentsModal tbody').html(html);
                    angular.element(document.querySelector('#viewDeleteDocumentsModal')).modal();

                } else {
                    var del = DocumentService.deleteDocumentById($scope.document.id);
                    del.then(function (success) {
                        jQuery('#deleteDocumentSuccessModal').modal();
                        angular.element(document.querySelector('#deleteDocumentSuccessModal')).modal();

                    }, function (failure) {
                        console.log(failure);
                    });
                }
            };

            $scope.modalIncludes.closeSuccesssModal = function () {
                window.location.pathname = "lms-web/management/viewdocuments/";
            };

            $scope.clickDocumentSave = function () {

                //Check if a new document was uploaded
                var documentList = $scope.updateDocument.documents;
                if (typeof documentList !== 'undefined' && documentList.length > 0) {
                    //If yes - toggle the confirmation 
                    $scope.confirmSaveDocument = true;
                } else {
                    //If no - save the page
                    $scope.sendDocumentEditCall();
                }
            };

            $scope.toggleSaveDocumentTooltip = function () {
                $scope.confirmSaveDocument = false;

            };

            $scope.sendDocumentEditCall = function () {

                $scope.confirmSaveDocument = true;
                

                var singleDocument = $scope.document;
                $scope.updateDocument.error.reset();
                $scope.updateDocument.success.reset();
                $scope.updateDocument.isLoading = true;

                var documentList = $scope.updateDocument.documents;
                var assetId = singleDocument.id;
                var allPromises = [];

                if (typeof documentList !== 'undefined' && documentList.length > 0) {
                    var documentToUpload = documentList[0];
                    if (documentToUpload.fileOrUrl === 'file' && !documentToUpload.fromRepo) {
                        allPromises.push(DocumentService.replaceAsset(documentToUpload.fileObject, assetId, 'file'));
                    } else if (documentToUpload.fileOrUrl === 'url') {
                        allPromises.push(DocumentService.replaceAsset(documentToUpload.name, assetId, 'url'));
                    }
                }

                //Need to check if the name changed
                if ($scope.document.originalName !== $scope.document.name) {
                    var editDocumentPromise = DocumentService.updateSingleAsset(singleDocument);
                    allPromises.push(editDocumentPromise);
                }

                $q.all(allPromises).then(function(success) {
                    $scope.updateDocument.isLoading = false;
                    $location.path('management/viewdocuments');
                }, function(failure) {
                    $scope.updateDocument.isLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.updateDocument.error.setError(errorCode, message);
                });
            };

            $scope.editdocumentAction = function() {
                $scope.homeTemplate = 'app/templates/management/editdocument/admin.html';

                $scope.createHeader = false;
                $scope.updateDocument.error.reset();
                $scope.updateDocument.success.reset();
                $scope.documentFormIsLoading = true;

                var params = $route.current.params.param.split('/');
                var docId = parseInt(params[0]);

                //To get the all courses for this resourse            
                var singleDocInfoPromise = DocumentService.getSingleDocumentInfo(docId);
                singleDocInfoPromise.then(function(success) {
                    $scope.document = success;
                    $scope.document.originalName = $scope.document.name;
                    $scope.updateDocument.documents = success;
                    $scope.documentFormIsLoading = false;

                    var AllCoursesByResourceInfoPromise = DocumentService.getAllCoursesByResource(docId);
                    AllCoursesByResourceInfoPromise.then(function (successData) {
                        $scope.deleteModal.CoursesData = successData;
                    }, function (failure) {
                        $scope.deleteModal.CoursesData = [];
                        console.log(failure);
                    });
                }, function(failure) {
                    $scope.documentFormIsLoading = false;

                });

              
                //End
                
            };

            $scope.SettingsTreeTitle = "Settings";
            $scope.settingsError = {};
            $scope.settingsError.error = errorDisplayerUtil.create();
            $scope.settingsSuccess = {};
            $scope.settingsSuccess.success = successDisplayerUtil.create();
            $scope.SettingsOptions = {};
            $scope.SettingsOptions.showLocks = false;
            $scope.SettingsOptions.canDrillDown = false;
            $scope.SettingsData = [{ 'name': 'Settings', 'nodes': [] }];
            $scope.settingsIsLoading = false;

            $scope.editsettingsAction = function () {
                $scope.homeTemplate = 'app/templates/management/editsettings/admin.html';

                $scope.settingsError.error.reset();
                $scope.settingsIsLoading = true;

                var settingsDataPromise = SettingsService.getSettingsWithAudit();
                settingsDataPromise.then(function (success) {
                    $scope.settingsIsLoading = false;
                    $scope.rows = success;
                    $scope.settings = success;

                }, function (failure) {
                    $scope.settingsIsLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.settingsError.error.setError(errorCode, message);
                });
            };

            $scope.SettingsNodeClick = function (node) {
                if (node.name === "Settings") {
                    $location.path('management/editsettings');
                }
            };

            $scope.sendSettingsEditCall = function () {
                var setting = $scope.settings;

                var allPromises = [];
                
                allPromises.push(SettingsService.updateSettings("sysPropDefaultMasteryScore", setting.features.sysPropDefaultMasteryScore.value));
                allPromises.push(SettingsService.updateSettings("EnrollmentLimitType", setting.features.enrollmentLimitType.value));
                if (setting.features.enrollmentLimitCount !== undefined) {
                    allPromises.push(SettingsService.updateSettings("EnrollmentLimitCount", setting.features.enrollmentLimitCount.value));
                }
                if (setting.features.enrollmentLimitDays !== undefined) {
                    allPromises.push(SettingsService.updateSettings("EnrollmentLimitDays", setting.features.enrollmentLimitDays.value));
                }
                if (setting.features.enrollmentLimitRefreshMonth.value !== undefined && setting.features.enrollmentLimitRefreshDate.value !== undefined) {
                    allPromises.push(SettingsService.updateSettings("EnrollmentLimitRefreshMonth", setting.features.enrollmentLimitRefreshMonth.value));
                    allPromises.push(SettingsService.updateSettings("EnrollmentLimitRefreshDate", setting.features.enrollmentLimitRefreshDate.value));
                }
                if (setting.features.sysPropCareerPathDashboard !== undefined && setting.features.sysPropCareerPathDashboard !== null) {
                    allPromises.push(SettingsService.updateSettings("sysPropCareerPathDashboard", setting.features.sysPropCareerPathDashboard));
                }
                if (setting.features.sysPropProxyMode !== undefined && setting.features.sysPropProxyMode !== null) {
                    allPromises.push(SettingsService.updateSettings("sysPropProxyMode", setting.features.sysPropProxyMode));
                }

                $q.all(allPromises).then(function (success) {
                    $scope.settingsSuccess.success.message = "Portal settings were successfully saved.";
                    $scope.settingsSuccess.success.thrown = true;
                }, function (failure) {
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.settingsError.error.setError(errorCode, message);
                });

            };

            /**
             * Method to sort any table on the management tab
             * @param {string} propertyName
             */
            $scope.sortBy = function (propertyName) {
                $scope.reverse = $scope.propertyName === propertyName ? !$scope.reverse : false;
                $scope.propertyName = propertyName;

                var searchTerm = $scope.management.managementSearch;

                _this.setStartandEndIndex();

                var sortedRows = [];
                if (searchTerm) {
                    var filtered = [];
                    if (_this.isOrgsListPage) {
                        filtered = $filter('filter')(_this.scopedViewListData, function (row) {
                                return ((row.name.toLowerCase()).indexOf((searchTerm.toLowerCase()) || '') !== -1 ||
                                        (row.parentOrgName.toLowerCase()).indexOf((searchTerm.toLowerCase()) || '') !== -1
                                );
                            }
                        );
                    } else if (_this.isJobsListPage) {
                        filtered = $filter('filter')(_this.scopedViewListData, function (row) {
                                return ((row.name.toLowerCase()).indexOf((searchTerm.toLowerCase()) || '') !== -1 ||
                                        (row.code.toLowerCase()).indexOf((searchTerm.toLowerCase()) || '') !== -1
                                );
                            }
                        );
                    }
                    sortedRows = $filter('orderBy')(filtered, $scope.propertyName, $scope.reverse);
                } else {
                    sortedRows = $filter('orderBy')(_this.scopedViewListData, $scope.propertyName, $scope.reverse);
                }
                _this.scopedViewListData = sortedRows;
                $scope.rows = sortedRows.slice($scope.startIndex, $scope.endIndex);
            };

            _this.calculatePages = function (items) {
                $scope.home.total = items.length;
            };

            $scope.treeOptions = {
                nodeChildren: 'nodes',
                templateUrl: 'tree.html',
                injectClasses: {
                    ul: "a1",
                    li: "a2",
                    liSelected: "a7",
                    iExpanded: "a3",
                    iCollapsed: "a4",
                    iLeaf: "a5",
                    label: "a6",
                    labelSelected: "a8"
                }
            };

            $scope.ValidatePortalRetakeMonth = function (settings) {
                settings.features.enrollmentLimitRefreshMonth.value = dateUtil.validMonth(settings.features.enrollmentLimitRefreshMonth.value);
                settings.features.enrollmentLimitRefreshDate.value = dateUtil.validDate(settings.features.enrollmentLimitRefreshMonth.value, settings.features.enrollmentLimitRefreshDate.value);
            };

            $scope.ValidatePortalRetakeDate = function (settings) {
                settings.features.enrollmentLimitRefreshDate.value = dateUtil.validDate(settings.features.enrollmentLimitRefreshMonth.value, settings.features.enrollmentLimitRefreshDate.value);
            };

            _this.formatToSkinnyOrgData = function (allorgs) {
                var temporgList = [];
                for (var i = 0; i < allorgs.length; i++) {
                    temporgList.push(_.pick(allorgs[i], "id", "name", "enabled", "parentOrgName", "isTopLevelOrg"));
                }
                return temporgList;
            };

            _this.setStartandEndIndex = function () {
                $scope.startIndex = ($scope.home.page * $scope.home.limit) - $scope.home.limit;
                $scope.endIndex = $scope.startIndex + $scope.home.limit;
            };

            $scope.toggleSaveSettingsTooltip = function () {
                jQuery('.confirmSettingsSave').toggle();
            };
			
			 $scope.toggleScoreSettingsTooltip = function () {	
               jQuery('.scorePopover').toggle();	
           };

            //LMS-7861 Need to close the PopUp Window showing the error message while uploading the document in create document page.            
            $scope.hidePopUpModal = function () {
                jQuery('#documentUploaderFileNameError').modal('hide');
            };

            // SLY-5342 Sub domain management for management admin tab
            $scope.isSubDomainAdmin = UsersService.isSubDomainAdmin();
            $scope.taDomains = UsersService.taDomains();
            $scope.activeDomain = UsersService.activeDomain();
            $scope.switchDomains = {};
            $scope.switchDomains.error = errorDisplayerUtil.create();

            $scope.onActiveDomainChanged = function (activeDomain, oldDomain) {
                var switchProfilePromise = UsersService.switchProfile(activeDomain.id);
                switchProfilePromise.then(function (success) {
                    location.reload();
                }, function (failure) {
                    $scope.switchDomains.error.setError(failure.StatusCode, failure.ErrorMessage);
                    jQuery("#switchDomainsError").show();
                    setTimeout(function () {
                        $scope.switchDomains.error.reset();
                        jQuery("#switchDomainsError").hide();
                    }, 2000);
                });
            };

            $scope.coursesRetakeResetSuccess = false;
            $scope.settingsRetakeReportIsLoading = false;

            $scope.GetCoursesNotDefaultRetakeReport = function () {
                $scope.settingsRetakeReportIsLoading = true;
                $scope.coursesRetakeResetSuccess = false;
                $scope.OpenCourseModal();
                var courses = SettingsService.getCoursesNotUsingDefaultRetake();
                courses.then(function (data) {
                    $scope.coursesNotDefaultRetakeReport = data;
                });
                $scope.settingsRetakeReportIsLoading = false;
            };
            
            $scope.OpenCourseModal = function () {
                jQuery('#nonDefaultRetakeCourseModal').modal('show');
            };

            $scope.ResetCoursesRetake = function () {
                $scope.settingsRetakeReportIsLoading = true;

                var setting = $scope.settings;
                var allPromises = [];

                allPromises.push(SettingsService.updateSettings("EnrollmentLimitType", setting.features.enrollmentLimitType.value));
                if (setting.features.enrollmentLimitCount !== undefined) {
                    allPromises.push(SettingsService.updateSettings("EnrollmentLimitCount", setting.features.enrollmentLimitCount.value));
                }
                if (setting.features.enrollmentLimitDays !== undefined) {
                    allPromises.push(SettingsService.updateSettings("EnrollmentLimitDays", setting.features.enrollmentLimitDays.value));
                }
                if (setting.features.enrollmentLimitRefreshMonth.value !== undefined && setting.features.enrollmentLimitRefreshDate.value !== undefined) {
                    allPromises.push(SettingsService.updateSettings("EnrollmentLimitRefreshMonth", setting.features.enrollmentLimitRefreshMonth.value));
                    allPromises.push(SettingsService.updateSettings("EnrollmentLimitRefreshDate", setting.features.enrollmentLimitRefreshDate.value));
                }

                $q.all(allPromises).then(function (success) {
                    var resetResponse = SettingsService.resetCoursesToPortalDefault("retake");
                    resetResponse.then(function (success) {
                        $scope.settingsRetakeReportIsLoading = false;
                        $scope.coursesRetakeResetSuccess = true;
                    }, function (failure) {
                        var errorCode = failure.StatusCode;
                        var message = failure.ErrorMessage;
                        $scope.settingsRetakeReportIsLoading = false;
                        $scope.settingsError.error.setError(errorCode, message);
                    });
                }, function (failure) {
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.settingsRetakeReportIsLoading = false;
                    $scope.settingsError.error.setError(errorCode, message);
                });
                
                
            };

            $scope.ResetCoursesScore = function () {

                var score = $scope.settings.features.sysPropDefaultMasteryScore.value;

                var saveScoreResponse = SettingsService.updateSettings("sysPropDefaultMasteryScore", score);
                saveScoreResponse.then(function (success) {
                    var resetResponse = SettingsService.resetCoursesToPortalDefault("score");
                    resetResponse.then(function (success) {
                        $scope.settingsSuccess.success.message = "Passing Score settings were successfully saved and applied to all courses.";
                        $scope.settingsSuccess.success.thrown = true;
                    }, function(failure) {
                        var errorCode = failure.StatusCode;
                        var message = failure.ErrorMessage;
                        $scope.settingsError.error.setError(errorCode, message);
                    });
                }, function (failure) {
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.settingsError.error.setError(errorCode, message);
                });

            };

            $scope.setStartTime = function (dateObj) {
                if (typeof dateObj !== "undefined" && dateObj !== null && typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment)) {
                    var portalStartTime = dateUtil.setPortalStartTime(dateObj.moment.toDate());
                    dateObj = dateUtil.formatDate(portalStartTime);
                } else {
                    dateObj = null;
                }
                return dateObj;
            };

            $scope.setEndTime = function (dateObj) {
                if (typeof dateObj !== "undefined" && dateObj !== null && typeof dateObj.moment !== "undefined" && _.isObject(dateObj.moment)) {
                    var portalEndTime = dateUtil.setPortalEndTime(dateObj.moment.toDate());
                    dateObj = dateUtil.formatDate(portalEndTime);
                } else {
                    dateObj = null;
                }
                return dateObj;
            };


            /**
             *
             * Custom Content - START
             *
             **/

            $scope.courseTaxonomyList = {};
            $scope.courseTaxonomyList.error = errorDisplayerUtil.create();
            $scope.courseTaxonomyList.title = "Course Taxonomy";
            $scope.courseTaxonomyList.treeData = [{ 'name': 'Course Taxonomy', 'nodes': [] }];
            $scope.courseTaxonomyList.treeData[0].nodes.push({ 'nodes': [], 'name': 'Active', 'value': true });
            $scope.courseTaxonomyList.treeData[0].nodes.push({ 'nodes': [], 'name': 'Inactive', 'value': false });
            $scope.courseTaxonomyList.options = {};
            _this.iscourseTaxonomyPage = false;

            $scope.documentTaxonomyList = {};
            $scope.documentTaxonomyList.error = errorDisplayerUtil.create();
            $scope.documentTaxonomyList.title = "Document Taxonomy";
            $scope.documentTaxonomyList.treeData = [{ 'name': 'Document Taxonomy', 'nodes': [] }];
            $scope.documentTaxonomyList.treeData[0].nodes.push({ 'nodes': [], 'name': 'Active', 'value': true });
            $scope.documentTaxonomyList.treeData[0].nodes.push({ 'nodes': [], 'name': 'Inactive', 'value': false });
            $scope.documentTaxonomyList.options = {};
            _this.isdocumentTaxonomyPage = false;
            
            /* Left hand navigation - node click */
            $scope.filterTaxonomy = function (taxonomyFilter, contentType) {

                /* If we're not on the Course Taxonomy list page,
                 * redirect to the Course Taxonomy list page and filter by active/inactive or none */
                if ((contentType === 'course' && !_this.iscourseTaxonomyPage) || (contentType === 'document' && !_this.isdocumentTaxonomyPage)) {
                    var searchParams = {};

                    if (taxonomyFilter.name === 'Course Taxonomy' || taxonomyFilter.name === 'Document Taxonomy') {
                        searchParams.taxonomyFilter = 'Active';
                    } else {
                        searchParams.taxonomyFilter = taxonomyFilter.name;
                    }

                    searchParams.contentType = contentType;

                    var taxonomyLocation = 'management/viewcustomcontent/';
                    searchParams = $httpParamSerializer(searchParams);

                    $location.path(taxonomyLocation).search(searchParams);
                }

                if (taxonomyFilter.name === 'Inactive') {
                    // Show all inactive course taxonomy
                    $scope.courseTaxonomyList.data = _.filter($scope.courseAndDocumentTaxonomy, function (courseTaxonomy) {
                        return (courseTaxonomy.isActive === false && courseTaxonomy.categoryType.toLowerCase() === contentType);
                    });
                }

                if (taxonomyFilter.name === 'Active' || taxonomyFilter.name === "Course Taxonomy" || taxonomyFilter.name === "Document Taxonomy") {
                    // Show all inactive course taxonomy
                    $scope.courseTaxonomyList.data = _.filter($scope.courseAndDocumentTaxonomy, function (courseTaxonomy) {
                        return (courseTaxonomy.isActive === true && courseTaxonomy.categoryType.toLowerCase() === contentType);
                    });
                }
            };


            $scope.viewcustomcontentAction = function () {
                $scope.courseTaxonomyList.error.reset();
                $scope.propertyName = 'categoryname';

                $scope.homeTemplate = 'app/templates/management/viewcustomcontent/admin.html';

                $scope.courseTaxonomyListIsLoading = true;
                /* select the node based on the filter and type */
                var filterContentType = $route.current.params.contentType;
                var filterSel = $route.current.params.taxonomyFilter.toLowerCase() === 'active' ? 0 : 1;
                if (filterContentType === 'course') {
                    $scope.courseTaxonomySelected = $scope.courseTaxonomyList.treeData[0];
                    //Determine if there is any filter selected, if so, expand courses and select the node
                    if (typeof ($route.current.params.taxonomyFilter) !== 'undefined') {
                        $scope.courseTaxonomySelected = $scope.courseTaxonomyList.treeData[0].nodes[filterSel];
                        $scope.courseTaxonomyExpanded = [$scope.courseTaxonomyList.treeData[0]];
                    }
                } else {
                    $scope.docTaxonomySelected = $scope.documentTaxonomyList.treeData[0];
                    //Determine if there is any filter selected, if so, expand documents and select the node
                    if (typeof ($route.current.params.taxonomyFilter) !== 'undefined') {
                        $scope.docTaxonomySelected = $scope.documentTaxonomyList.treeData[0].nodes[filterSel];
                        $scope.docTaxonomyExpanded = [$scope.documentTaxonomyList.treeData[0]];
                    }
                }


                var taxonGet = CategoriesService.fetch(null, false, true);
                taxonGet.then(function (success) {
                    var filterContentType = $route.current.params.contentType;
                    success = CategoriesService.RemoveDocumentPolicies(success);
                    if (filterContentType === 'course') { 
                        _this.iscourseTaxonomyPage = true; 
                    } else { _this.isdocumentTaxonomyPage = true;}
                    $scope.courseAndDocumentTaxonomy = success;
                    var courseTaxonomy = $filter('filter')(success, { categoryType: filterContentType });
                    $scope.courseTaxonomyList.data = courseTaxonomy;
                    _this.courseTaxonomyData = courseTaxonomy;
                    if ($route.current.params.taxonomyFilter) {

                        var filter = $route.current.params.taxonomyFilter;
                        var type = $route.current.params.contentType;
                        $scope.filterTaxonomy({ 'name': filter }, type);
                    }

                    $scope.courseTaxonomyListIsLoading = false;

                }, function (failure) {
                        $scope.courseTaxonomyListIsLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.courseTaxonomyList.error.setError(errorCode, message);
                });

            };


            // create and edit subcategory
            $scope.subcategoryForm = {};
            $scope.subcategory = {};
            $scope.subcategory.imageID = 8;//Default image to 8 for new subcategories
            $scope.categoryList = {};
            $scope.createCategory = false;
            $scope.categoryError = errorDisplayerUtil.create();
            $scope.subcatDeactivate = [];//for the deactivate popover
            $scope.createcategoryAction = function () {
                $scope.homeTemplate = "app/templates/management/createCategory/admin.html";
                $scope.createCategory = true;
                $scope.categoryType = ['Course', 'Document'];
                getCategories(false);

            };

            $scope.CreateCategoryValues = function () {
                $scope.disableCategoryDropDown = false;
                var type = $scope.subcategory.categoryType;
                if (type !== undefined && type.toLowerCase() === 'course') {
                    $scope.uniqueCategories = $filter('filter')($scope.allUniqueCategories, { categoryType: "course" });
                } else if (type !== undefined && type.toLowerCase() === 'document') {
                    $scope.uniqueCategories = $filter('filter')($scope.allUniqueCategories, { categoryType: "document" });
                } else {
                    $scope.disableCategoryDropDown = true;
                    $scope.subcategory.category = undefined;
                }
            };

            
            /* Edit a subcategory */
            $scope.editcategoryAction = function () {
                $scope.homeTemplate = "app/templates/management/editCategory/admin.html";
                getCategories(true);                   

            };

            $scope.showOnlyDocumentTaxonomy = function () {
                $scope.uniqueCategories = $filter('filter')($scope.allUniqueCategories, { categoryType: "document" });
                $scope.hideCategoryTypeDropdown = true;
                $scope.disableCategoryDropDown = false;
                $scope.subcategory.categoryType = 'document';
            };

            /* handles retrieving catalog data and formatting scoped variables */
                /* used on both the create new subcategory and edit subcategory */
            var getCategories = function (editCategory) {
                
                $scope.createCategoryLoading = true;
                $scope.disableCategoryTypeDropdown = false;
                $scope.hideCategoryTypeDropdown = window.customDocumentsEnabled ? false : true;

                var taxonGet = CategoriesService.fetch(null, false, true);
                taxonGet.then(function (success) {
                    $scope.createCategoryLoading = false;
                    success = CategoriesService.RemoveDocumentPolicies(success);
                    $scope.categoryList = angular.copy(success);
                    $scope.disableCategoryDropDown = window.customDocumentsEnabled ? true : false; 
                  
                    //unique categories for the category dropdown
                    $scope.uniqueCategories = [];
                    $scope.allUniqueCategories = []; 
                    success.forEach(function (item) {
                        var catInd = _.filter($scope.allUniqueCategories, { "categoryID": item.categoryID }); 
                        if (catInd.length < 1) {
                            var cat = { 'categoryName': item.categoryname, 'categoryID': item.categoryID, 'categoryNodeID': item.categoryNodeID, 'categoryType': item.categoryType };
                            $scope.allUniqueCategories.push(cat); 
                        }
                    });

                    if (!window.customDocumentsEnabled) {
                        $scope.uniqueCategories = $filter('filter')($scope.allUniqueCategories, { categoryType: "course" });
                        $scope.subcategory.categoryType = 'course';
                    }

                    /* putting this down here as well because of race conditions */
                    if ($scope.showCustomContent !== undefined && !$scope.showCustomContent) {
                         $scope.showOnlyDocumentTaxonomy();
                    }

                    /* some extra steps when editing the subcategory */
                    if (editCategory) {
                        $scope.disableCategoryDropDown = false;
                        $scope.disableCategoryTypeDropdown = true;
                        $scope.uniqueCategories = $scope.allUniqueCategories; 

                        /* get the current subcategory from the categoryList that is being edited */
                        var params = $route.current.params.param.split('/');
                        var categoryID = params[0];
                        var idxCat = _.findIndex($scope.categoryList, { 'subcategoryID': parseInt(categoryID) });
                  
                        $scope.subcategory = $scope.categoryList[idxCat];
                        $scope.subcategory.category = { 'categoryName': $scope.subcategory.categoryname, 'categoryID': $scope.subcategory.categoryID, 'categoryNodeID': $scope.subcategory.categoryNodeID };
                        //Creating a variable to use in the heading - otherwise changes to the Name will appear in the header - Thats messy
                        $scope.nameInHeading = angular.copy($scope.subcategory.subcategoryName);
                        //populate the category type drop down
                        $scope.categoryType = $scope.subcategory.categoryType.toLowerCase() === 'course' ? ['Course'] : ['Document'];

                        var subCatType = $filter('filter')($scope.categoryList, { subcategoryID: $scope.subcategory.subcategoryID });
                        if (subCatType.length > 0) {
                            var type = subCatType[0].categoryType;
                            if (type !== undefined && type.toLowerCase() === 'course') {
                                $scope.uniqueCategories = $filter('filter')($scope.allUniqueCategories, { categoryType: "course" });
                            } else {
                                $scope.uniqueCategories = $filter('filter')($scope.allUniqueCategories, { categoryType: "document" });
                            }
                        } 

                        //Remove the editing subcategory from the categoryList for the unique name validation
                        $scope.categoryList.splice(idxCat, 1);

                        //Default to 8 if they haven't picked an image
                        $scope.selectedImage = $scope.subcategory.imageName !== null ? $scope.subcategory.imageName : 'grab_bag/8';
                        $scope.current = $scope.subcategory.imageName !== null ? $scope.subcategory.imageName : "grab_bag/8";
                    }

                }, function (failure) {
                    $scope.createCategoryLoading = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.categoryError.setError(errorCode, message);
                });

            };

            /* create new category or save existing category */
            $scope.saveCategory = function (deactivate) {

                //set up end date and isActive properties depening on deactivate flag
                if (typeof deactivate !== 'undefined' && deactivate === true) {
                    $scope.subcategory.endDate = new Date();
                    $scope.subcategory.isActive = false;
                } else if (typeof deactivate !== 'undefined' && deactivate === false) {
                    $scope.subcategory.endDate = null;
                    $scope.subcategory.isActive = true;
                }
         
                $scope.categoryError.reset();
                $scope.createCategoryLoading = true;
                var categoryCall = $scope.createCategory ? CategoriesService.createCategory($scope.subcategory) : CategoriesService.editCategory($scope.subcategory);
                //var createCatCall = CategoriesService.createCategory($scope.subcategory);

                categoryCall.then(function (success) {
                    $scope.createCategoryLoading = false;

                    //Call BAI Catalog to refresh the service stored Catalog Data
                    CatalogService.getBaiCatalogData(true);
                    //successful - redirect to Course Taxonomy view page
                    $location.url('management/viewcustomcontent/?taxonomyFilter=Active&contentType=' + $scope.subcategory.categoryType.toLowerCase());
                },
                function (failure) {
                    $scope.createCategoryLoading = false;
                    $scope.subcatDeactivate.Popover = false;
                    var errorCode = failure.StatusCode;
                    var message = failure.ErrorMessage;
                    $scope.categoryError.setError(errorCode, message);
                    //if the error message is the active courses message - undo the deactive subcategory settings
                    if (failure.ErrorMessage.indexOf("Active courses") >= 0 && deactivate) {
                        $scope.subcategory.endDate = null;
                        $scope.subcategory.isActive = true;
                    }

                });
          

            };

            /* START - Image selection modal */

            $scope.tileImageSelectModalTitle = "Subcategory Tile Images";
            $scope.selectedImage = "grab_bag/8"; //used to add selected class to image in modal
            $scope.current = "grab_bag/8";// Change later to get the image of the node - default is 8 for creation

            /* Open tile image selection modal */
            $scope.imageSelect = function () {
                angular.element(document.querySelector('#imageSelectionModal')).modal();
                $scope.fetchImageList();
            };

            $scope.fetchImageList = function () {
                $scope.shownImages = [];
                $scope.shownImages.push($scope.current);

                //Add default image
                if ($scope.current !== "grab_bag/8" && $scope.current !== '8') {
                    $scope.shownImages.push('grab_bag/8');
                }
                //Add the rest of the grab_bag images
                for (i = 21; i < 71; i++) {
                    temp = "grab_bag/" + i.toString();
                    if ($scope.current !== temp) $scope.shownImages.push(temp);
                }
            };

            $scope.select = function ($event, selectedstring) {
                $scope.selectedImage = selectedstring;
                $scope.current = selectedstring;
            };

            $scope.finalizeSelection = function () {
                $scope.subcategory.imageName = $scope.selectedImage;
                angular.element(document.querySelector('#imageSelectionModal')).modal('hide');
            };

            $scope.closeImageSelectionModal = function () {
                angular.element(document.querySelector('#imageSelectionModal')).modal('hide');
            };

            /* END - Image selection modal */


            /**
            *
            * Course Taxonomy - END
            *
            **/

            
        };
    }
]);

app.controller("adminHeaderController", [
  "$rootScope",
  "$location",
  "SettingsService",
  "CssService",
  "UsersService",
  function ($rootScope, $location, SettingsService, CssService, UsersService) {
    $rootScope.settingsIsLoading = true;
    SettingsService.getSettings().then(function (success) {
      $rootScope.enableAdminDashboardTab = SettingsService.isILTEnabled();
      $rootScope.enableCareerPathingTab = SettingsService.isCareerPathEnabled();
      $rootScope.enableTrainingInsightsTab =
        SettingsService.isTrainingInsightsEnabled();
      $rootScope.settingsIsLoading = false;

      var path = $location.$$path.toLowerCase();
      if (
        (path.startsWith("/insights/") &&
          !$rootScope.enableTrainingInsightsTab) ||
        (path.startsWith("/dashboard/") && !$rootScope.enableCareerPathingTab)
      ) {
        $location.url("/users/home/show-tabbed-header");
      }
    });

    $rootScope.portalBackgroundColor = CssService.extractColorFromCSS(
      CssService.currentcss
    );

    if ($rootScope.portalBackgroundColor === null) {
      $rootScope.portalBackgroundColor = "#1eabe3"; //default to BAI blue
    }

    var originatorEv;

    $rootScope.openMenu = function ($mdMenu, ev) {
      originatorEv = ev;
      $mdMenu.open(ev);
    };

    $rootScope.logout = function () {
      var portalCode = UsersService.getPortalCode();
      var logout = UsersService.clearSession();
      logout.then(
        function (success) {
          // If a user's portal code existed while being logged in, persist it on the logged out state.
          if (portalCode !== "" && portalCode !== null) {
            // alert('logging out with portalCode');
            portalCode = "?portal=" + portalCode;
          }

          $location.url("/" + portalCode);
        },
        function (failure) {}
      );
    };

    $rootScope.adminTabIds = {
      people: "people",
      content: "content",
      rules: "rules",
      management: "management",
      insights: "insights",
      careerPathing: "careerPathing",
    };

    Object.freeze($rootScope.adminTabIds);

    $rootScope.getSelectedAdminTab = function () {
      var path = $location.$$path.toLowerCase();
      if (path.includes("/users/")) {
        return $rootScope.adminTabIds.people;
      } else if (path.startsWith("/courses/")) {
        return $rootScope.adminTabIds.content;
      } else if (path.startsWith("/rules/")) {
        return $rootScope.adminTabIds.rules;
      } else if (path.startsWith("/management/")) {
        return $rootScope.adminTabIds.management;
      } else if (path.startsWith("/insights/")) {
        return $rootScope.adminTabIds.insights;
      } else if (path.startsWith("/dashboard/")) {
        return $rootScope.adminTabIds.careerPathing;
      } else {
        return $rootScope.adminTabIds.people;
      }
    };

    $rootScope.selectedAdminTab = $rootScope.getSelectedAdminTab();

    $rootScope.redirectByActiveTab = function () {
      switch ($rootScope.selectedAdminTab) {
        case "dashboard":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Dashboard",
            "_blank"
          );
          break;
        case "people":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#People",
            "_blank"
          );
          break;
        case "content":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Content",
            "_blank"
          );
          break;
        case "rules":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Rules",
            "_blank"
          );
          break;
        case "management":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Management",
            "_blank"
          );
          break;
        case "recommendations":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Recommendations",
            "_blank"
          );
          break;
        case "insights":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Training-Insights",
            "_blank"
          );
          break;
        case "careerPathing":
          window.open(
            "https://ld-connect.bai.org/learning-manager/training-administrator-user-manual/#Create-Career-Path",
            "_blank"
          );
          break;
        default:
          break;
      }
    };

    $rootScope.helpMenuClick = function (menuItem) {
      switch (menuItem) {
        case "usermanuals":
          $rootScope.redirectByActiveTab();
          break;
        case "trainingvideos":
          console.log("clicked");
          window.open(
            "https://ld-connect.bai.org/learning-manager/watch-instructional-videos/",
            "_blank"
          );
          break;
        case "faqs":
          window.open(
            "https://ld-connect.bai.org/learning-manager/frequently-asked-questions/",
            "_blank"
          );
          break;
        case "walkthroughs":
          window.open(
            "https://ld-connect.bai.org/learning-manager/walkthrough-videos/",
            "_blank"
          );
          break;
        case "ldconnect":
          window.open("https://ld-connect.bai.org/", "_blank");
          break;
        case "contactus":
          window.open(
            "https://ld-connect.bai.org/learning-manager/technical-support/",
            "_blank"
          );
          break;
        default:
          break;
      }
    };
  },
]);

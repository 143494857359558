app.service('SettingsService', ['$http', '$q', 'TimezoneService', function ($http, $q, TimezoneService) {
    var _this = this;

    _this.response = null;
    _this.maxEnrollmentAttemptsAllowed = -1;
    _this.features = {};
    var insightsAccess = "";
    var paymentOverdue = false;

    _this.getSettings = function (cacheOptions) {

        var req = {
            method: 'GET',
            url: 'api/settings/',
            headers: {
                'Content-Type': 'application/json',
            },
            cache: true,
            timeout: window.timeout
        };

        return $http(req).then(function(success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function(failure) {
            throw failure;
        });
    };

    _this.getSettingsWithAudit = function () {
      var req = {
          method: 'GET',
          url: 'api/settings/audit' + '?v=' + Math.random(),
          headers: {
              'Content-Type': 'application/json',
          },
          timeout: window.timeout
      };

      return $http(req).then(function(success) {
          _this.response = _this.format(success.data);

          return _this.response;
      }, function(failure) {
          throw failure;
      });
  };

    _this.updateSettings = function (key, value) {
        var req = {
            method: 'POST',
            url: 'api/settings/' +key,
            data: value,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            _this.response = _this.format(success.data);

            return _this.response;
        }, function (failure) {
            throw failure;
        });

    };

    /**
     * Format all data that comes in
     *
     * parse force it to an int
     */
    _this.format = function (response) {
        if (response.enrollmentAttempts === parseInt(response.enrollmentAttempts, 10)) {
            _this.maxEnrollmentAttemptsAllowed = parseInt(response.enrollmentAttempts, 10);
        } else {
            throw new Error('Could not convert maxEnrollmentAttemptsAllowed data to integer.');
        }

        if (response.features === null || response.features === undefined) {
            throw new Error("Could not convert features data to an object");
        }

        var sysProperties = response.features;

        if (sysProperties.sysPropInstructorLed === null || sysProperties.sysPropInstructorLed === undefined)  
            sysProperties.sysPropInstructorLed = false;
        if (sysProperties.sysPropCourseGroups === null || sysProperties.sysPropCourseGroups === undefined)  
            sysProperties.sysPropCourseGroups = false;
        if (sysProperties.sysPropTasks === null || sysProperties.sysPropTasks === undefined)  
            sysProperties.sysPropTasks = false;
        if (sysProperties.sysPropIDesign === null || sysProperties.sysPropIDesign === undefined)  
            sysProperties.sysPropIDesign = false;
        if (sysProperties.sysPropVirtualClassroom === null || sysProperties.sysPropVirtualClassroom === undefined)  
            sysProperties.sysPropVirtualClassroom = false;
        if (sysProperties.sysPropLDConnect === null || sysProperties.sysPropLDConnect === undefined)  
            sysProperties.sysPropLDConnect = false;
        if (sysProperties.sysPropEarlyAccess === null || sysProperties.sysPropEarlyAccess === undefined)  
            sysProperties.sysPropEarlyAccess = false;
        if (sysProperties.sysPropTrainingInsights === null || sysProperties.sysPropTrainingInsights === undefined)
            sysProperties.sysPropTrainingInsights = false;
        if (sysProperties.sysPropCareerPath === null || sysProperties.sysPropCareerPath === undefined) {
            sysProperties.sysPropCareerPath = false;
        }
        if (sysProperties.sysPropCareerPathDashboard === undefined || sysProperties.sysPropCareerPathDashboard === null) {
            sysProperties.sysPropCareerPathDashboard = false;
        }
        if (sysProperties.sysPropProxyMode === null || sysProperties.sysPropProxyMode === undefined) {
            sysProperties.sysPropProxyMode = false;
        }
        if (sysProperties.sysPropRusticiAccess === null || sysProperties.sysPropRusticiAccess === undefined) {
            sysProperties.sysPropRusticiAccess = false;
        }
        if (sysProperties.sysPropDocument === null || sysProperties.sysPropDocument === undefined) {
            sysProperties.sysPropDocument = false;
        }
        if (sysProperties.sysPropOverdue === null || sysProperties.sysPropOverdue === undefined) {
            sysProperties.sysPropOverdue = false;
        }
        if (sysProperties.enableLmsPlmIntegration === null || sysProperties.enableLmsPlmIntegration === undefined){
            sysProperties.enableLmsPlmIntegration = false;
        }
        
        insightsAccess = sysProperties.sysPropTrainingInsights;
        paymentOverdue = sysProperties.sysPropOverdue;

        if (typeof sysProperties['orgProp.timezone'] !== 'undefined' && sysProperties['orgProp.timezone'] !== null) {
            if (TimezoneService.timezones === 'undefined' || TimezoneService.timezones === null)
            { TimezoneService.getAllTimezones(); }

            sysProperties['orgProp.timezone'].details = TimezoneService.getTimeZoneById(parseInt(sysProperties['orgProp.timezone'].value, 10));
            if (typeof sysProperties['orgProp.timezone'].lastUpdateOn !== 'undefined')
                sysProperties['orgProp.timezone'].lastUpdateOn = new Date(sysProperties['orgProp.timezone'].lastUpdateOn).toLocaleString();
            if (typeof sysProperties['orgProp.timezone'].beforeValue !== 'undefined' && sysProperties['orgProp.timezone'].beforeValue !== null) {
                var previousTimezone = TimezoneService.getTimeZoneById(parseInt(sysProperties['orgProp.timezone'].beforeValue, 10));
                sysProperties['orgProp.timezone'].beforeValueText = previousTimezone.displayName; 
            }
            response.timezone = sysProperties['orgProp.timezone'];
        }

        if (typeof sysProperties.sysPropDefaultMasteryScore !== 'undefined' && sysProperties.sysPropDefaultMasteryScore !== null) {
            if (typeof sysProperties.sysPropDefaultMasteryScore.lastUpdateOn !== "undefined")
                sysProperties.sysPropDefaultMasteryScore.lastUpdateOn = new Date(sysProperties.sysPropDefaultMasteryScore.lastUpdateOn).toLocaleString();
        }

        if (sysProperties.enrollmentLimitType === undefined) {
            sysProperties.enrollmentLimitType = { lastUpdateOn: "2000-01-01T00:00:00Z" };
        }
        if (sysProperties.enrollmentLimitCount === undefined) {
            sysProperties.enrollmentLimitCount = { lastUpdateOn: "2000-01-01T00:00:00Z" };
        }
        if (sysProperties.enrollmentLimitDays === undefined) {
            sysProperties.enrollmentLimitDays = { lastUpdateOn: "2000-01-01T00:00:00Z" };
        }
        if (sysProperties.enrollmentLimitRefreshMonth === undefined) {
            sysProperties.enrollmentLimitRefreshMonth = { lastUpdateOn: "2000-01-01T00:00:00Z" };
        }
        if (sysProperties.enrollmentLimitRefreshDate === undefined) {
            sysProperties.enrollmentLimitRefreshDate = { lastUpdateOn: "2000-01-01T00:00:00Z" };
        }

        var retakeLimitDetails = [
            sysProperties.enrollmentLimitType,
            sysProperties.enrollmentLimitCount,
            sysProperties.enrollmentLimitDays,
            sysProperties.enrollmentLimitRefreshMonth,
            sysProperties.enrollmentLimitRefreshDate
        ];

        retakeLimitDetails.sort(function (a, b) { return new Date(b.lastUpdateOn) - new Date(a.lastUpdateOn); });
        response.retakeLimitDetails = {};
        response.retakeLimitDetails.lastUpdateOn = new Date(retakeLimitDetails[0].lastUpdateOn).toLocaleString();
        response.retakeLimitDetails.lastUpdateBy = retakeLimitDetails[0].lastUpdateBy;

        var typeDate = new Date(sysProperties.enrollmentLimitType.lastUpdateOn);
        var countDate = new Date(sysProperties.enrollmentLimitCount.lastUpdateOn);
        var daysDate = new Date(sysProperties.enrollmentLimitDays.lastUpdateOn);
        var monthDate = new Date(sysProperties.enrollmentLimitRefreshMonth.lastUpdateOn);
        var dateDate = new Date(sysProperties.enrollmentLimitRefreshDate.lastUpdateOn);
        var latestDate = new Date(Math.max.apply(null, [typeDate, countDate, daysDate, monthDate, dateDate]));
        var rollingLastUpdated = new Date(Math.max.apply(null, [countDate, daysDate]));
        var annualLastUpdated = new Date(Math.max.apply(null, [countDate, monthDate, dateDate]));

        var retakeCount = null,
            retakeDays = null,
            retakeMonth = null,
            retakeDate = null;
        // type change
        if (typeDate >= latestDate) {
            if (sysProperties.enrollmentLimitType.beforeValue === "2") {
                response.retakeLimitDetails.beforeValueText = "Unlimited";
            }
            else if (sysProperties.enrollmentLimitType.beforeValue === "4") {//rolling
                retakeCount = rollingLastUpdated >= countDate && countDate < latestDate ? sysProperties.enrollmentLimitCount.value : sysProperties.enrollmentLimitCount.beforeValue;
                retakeDays = rollingLastUpdated >= daysDate ? sysProperties.enrollmentLimitDays.value : sysProperties.enrollmentLimitDays.beforeValue;
                response.retakeLimitDetails.beforeValueText = retakeCount + " attempts every " + retakeDays + " days.";
            }
            else if (sysProperties.enrollmentLimitType.beforeValue === "5") {//annual
                retakeCount = annualLastUpdated >= countDate && countDate < latestDate ? sysProperties.enrollmentLimitCount.value : sysProperties.enrollmentLimitCount.beforeValue;
                retakeMonth = annualLastUpdated >= monthDate ? sysProperties.enrollmentLimitRefreshMonth.value : sysProperties.enrollmentLimitRefreshMonth.beforeValue;
                retakeDate = annualLastUpdated >= dateDate ? sysProperties.enrollmentLimitRefreshDate.value : sysProperties.enrollmentLimitRefreshDate.beforeValue;
                response.retakeLimitDetails.beforeValueText = retakeCount + " attempts starting annually on " + retakeMonth + "/" + retakeDate + ".";
            }
        }
        // change in rolling
        else if (sysProperties.enrollmentLimitType.value === "4") {
            retakeCount = countDate < rollingLastUpdated ? sysProperties.enrollmentLimitCount.value : sysProperties.enrollmentLimitCount.beforeValue;
            retakeDays = daysDate < rollingLastUpdated ? sysProperties.enrollmentLimitDays.value : sysProperties.enrollmentLimitDays.beforeValue;
            response.retakeLimitDetails.beforeValueText = retakeCount + " attempts every " + retakeDays + " days.";
        }
        
        //change in annual
        else {
            retakeCount = countDate < annualLastUpdated ? sysProperties.enrollmentLimitCount.value : sysProperties.enrollmentLimitCount.beforeValue;
            retakeMonth = monthDate < annualLastUpdated ? sysProperties.enrollmentLimitRefreshMonth.value : sysProperties.enrollmentLimitRefreshMonth.beforeValue;
            retakeDate = dateDate < annualLastUpdated ? sysProperties.enrollmentLimitRefreshDate.value : sysProperties.enrollmentLimitRefreshDate.beforeValue;
            response.retakeLimitDetails.beforeValueText = retakeCount + " attempts starting annually on " + retakeMonth + "/" + retakeDate + ".";
        }

        // Assign for later getters
        _this.features = sysProperties;

        return response;
    };

    _this.isPlmIntegrationEnabled = function () {
        return _this.features.enableLmsPlmIntegration === true;
    };
        
    _this.isILTEnabled = function() {
        return _this.features.sysPropInstructorLed === true;
    };

    _this.isCareerPathEnabled = function() {
        return _this.features.sysPropCareerPath === true;
    };

    _this.isCareerPathDashboardEnabled = function () {
        return _this.isCareerPathEnabled() && _this.features.sysPropCareerPathDashboard === true;
    };

    _this.isTrainingInsightsEnabled = function () {
        return insightsAccess;
    };

    _this.isProxyModeEnabled = function () {
        return _this.features.sysPropProxyMode === true;
    };

    _this.isDocumentsEnabled = function () {
        return _this.features.sysPropDocument === true;
    };

    _this.isOverdue = function () {
        return paymentOverdue;
    };

    _this.getCoursesNotUsingDefaultRetake = function () {
        var req = {
            method: 'GET',
            url: 'api/settings/getcourses',
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache'
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return _this.formatCourses(success);
        }, function (failure) {
            throw failure;
        });
    };

    _this.resetCoursesToPortalDefault = function (courseSetting) {
        var req = {
            method: 'POST',
            url: 'api/settings/resetcourses/' + courseSetting,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: window.timeout
        };

        return $http(req).then(function (success) {
            return success.data;
        }, function (failure) {
            throw failure;
        });
    };

    _this.formatCourses = function (response){
        var courses = response.data;
        var i;
        for(i = 0; i < courses.length; i++)
        {
            switch (courses[i].retakeRestriction){
                case 'annual':
                    courses[i].retakeRestrictionName = 'Annual';
                    break;
                case 'allowedEnrollments':
                    courses[i].retakeRestrictionName = 'Rolling';
                    break;
                case 'unlimited':
                    courses[i].retakeRestrictionName = 'Unlimited';
                    break;
            }
        }
        return courses;
    };

}]);